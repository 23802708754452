import React, { useState } from "react";
import { Link } from "react-router-dom";

//
// This is the cookie banner
//
function CookieBanner(props) {
    const reset = props.reset;

    function concent() {
      // All cookies
      localStorage.setItem("cookie_consent", "true");
      localStorage.setItem("cookie_consent_date", (new Date()).toString());
      setShow(false);
    }
  
    function refuse() {
      // Only essential cookies
      localStorage.setItem("cookie_consent", "false");
      localStorage.setItem("cookie_consent_date", (new Date()).toString());
      setShow(false);
    }
  
    function showCookieBanner() {
      const c_cons = localStorage.getItem("cookie_consent");
      const c_cons_date = localStorage.getItem("cookie_consent_date");
  
      if (!(c_cons_date === null)) {
        const now = new Date();
        const then = Date.parse(c_cons_date);
  
        if (now - then > 300*24*60*60*1000) {
          return true;
        }
      }
  
      if (c_cons === null || c_cons_date === null) {
        return true;
      }
  
      return false;
    }
  
    const [show, setShow] = React.useState(showCookieBanner());

    if (!show) {
      return null;
    }

    return (
      <div className="cookies-eu-banner">
        <div className="w3-row">
          <div className="w3-col l8 s12">
          <p>This website uses cookies to improve your experience. Please see the <Link to="/cookie-policy">cookie policy</Link> for details.</p>        
          </div>
          <div className="w3-col l4 s12">
            <div className="w3-bar">
              <div className="w3-bar-item w3-button w3-border w3-round-large w3-blue w3-margin-left" onClick={concent}><b>Accept all cookies</b></div>
              <div className="w3-bar-item w3-button w3-border w3-round-large w3-blue w3-margin-left" onClick={refuse}>Accept only essential cookies</div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CookieBanner;


//
// Parse a markdown paragraph (or some other code snippet) into a list of elements.
//
// N.B. This currently only supports *, _, **, __, [text]{url}, #+
//
function parse_markdown_paragraph(par) {
    var current_text = "";
    var current_element = [];
    var substr = "";
    var i = 0;

    console.log(par);

    // Handle headers
    var header_count = 0;
    for (i = 0; i < par.length && i < 6 && par[i] == '#'; i++) {
        header_count++;
    }
    if (header_count > 0) {
        // Return substring of part after the header
        substr = "";
        for (i = header_count; i < par.length; i++) {
            substr += par[i];
        }
        if (substr.length > 0) {
            const content = parse_markdown_paragraph(substr);
            current_element.push({ type: "h" + header_count, content: content });
        }
        return current_element;
    }

    // Handle other elements
    for (i = 0; i < par.length; i++) {
        if (par[i] == '*' || par[i] == '_') {
            // Handle ** and __
            const marker = par[i];
            if (i < par.length + 1 && par[i + 1] == marker) {
                if (current_text.length > 0) {
                    current_element.push({ type: "text", content: current_text });
                    current_text = "";
                }
                substr = "";
                for (i = i + 2; i < par.length + 1 && !(par[i] == marker && par[i + 1] == marker); i++) {
                    substr += par[i];
                }
                if (substr.length > 0) {
                    const content = parse_markdown_paragraph(substr);
                    current_element.push({ type: "strong", content: content });
                }
            }
            else {
                // Handle * and _
                if (current_text.length > 0) {
                    current_element.push({ type: "text", content: current_text });
                    current_text = "";
                }
                substr = "";
                for (i = i + 1; i < par.length && par[i] != marker; i++) {
                    substr += par[i];
                }
                if (substr.length > 0) {
                    const content = parse_markdown_paragraph(substr);
                    current_element.push({ type: "em", content: content });
                }
            }
        }
        else if (par[i] == '[') {
            if (current_text.length > 0) {
                current_element.push({ type: "text", content: current_text });
                current_text = "";
            }
            substr = "";
            for (i = i + 1; i < par.length && par[i] != ']'; i++) {
                substr += par[i];
            }
            if (substr.length > 0 && i < par.length + 1 && par[i + 1] == '(') {
                var url = "";
                for (i = i + 2; i < par.length && par[i] != ')'; i++) {
                    url += par[i];
                }
                const content = parse_markdown_paragraph(substr);
                current_element.push({ type: "link", content: content, url: url });
            }
        }
        else {
            if (par[i] != '\\') {
                current_text += par[i];
            }
        }
    }

    if (current_text.length > 0) {
        current_element.push({ type: "text", content: current_text });
        current_text = "";
    }

    return current_element;
}

//
// Parse markdown text into a nested dictionary of parsed eleemnts.
//
function parse_markdown(text) {
    // Split to paragraphs
    var pars = text.split(/\r?\n\r?\n/);

    console.log(pars);

    // Parse each paragraph
    const elements = pars.map(par => {
        const content = parse_markdown_paragraph(par);
        return { type: "p", content: content };
    });

    return elements;
}

//
// Convert markdown elements to HTML
//
function markdown_elements_to_html(elements) {
    return (
      elements.map(elem => {
        if (elem.type == "text") {
          return elem.content;
        }
        else if (elem.type == "p") {
          return <p>{markdown_elements_to_html(elem.content)}</p>;
        }
        else if (elem.type == "h1") {
            return <h1><b>{markdown_elements_to_html(elem.content)}</b></h1>;
        }
        else if (elem.type == "h2") {
            return <h2><b>{markdown_elements_to_html(elem.content)}</b></h2>;
        }
        else if (elem.type == "h3") {
            return <h3><b>{markdown_elements_to_html(elem.content)}</b></h3>;
        }
        else if (elem.type == "h4") {
            return <h4><b>{markdown_elements_to_html(elem.content)}</b></h4>;
        }
        else if (elem.type == "h5") {
            return <h5><b>{markdown_elements_to_html(elem.content)}</b></h5>;
        }
        else if (elem.type == "h6") {
            return <h6><b>{markdown_elements_to_html(elem.content)}</b></h6>;
        }
        else if (elem.type == "em") {
          return <em>{markdown_elements_to_html(elem.content)}</em>;
        }
        else if (elem.type == "strong") {
          return <strong>{markdown_elements_to_html(elem.content)}</strong>;
        }
        else if (elem.type == "link") {
          return <a href={elem.url}>{markdown_elements_to_html(elem.content)}</a>;
        }
        else {
          return " (unsupported markdown element) ";
        }
      })
    );
}
    
//
// Convert markdown text to HTML
//
function markdown_to_html(text) {
    const elements = parse_markdown(text);

    return markdown_elements_to_html(elements);
}


//
// Format the article age
//
function article_age(published, now = new Date()) {
    if (published === "" || published === null) {
      return "- not yet";
    }
  
    var date = published.split(/[- :]/);
    date[1]--; // Convert month to 0-based index
  
    var sqldate = new Date(Date.UTC(...date));
  
    var diff_hours = (now.getTime() - sqldate.getTime()) / 3600000;

    if (diff_hours < 0) {
      return "after " + Math.round(-diff_hours) + " hours";
    }
  
    if (diff_hours < 1) {
      return "less than an hour ago";
    }
  
    if (diff_hours < 24) {
        const hours = Math.round(diff_hours);
        if (hours == 1) {
            return "1 hour ago";
        }
        return hours + " hours ago";
    }
  
    if (diff_hours < 24 * 30) {
        const days = Math.round(diff_hours / 24);
        if (days == 1) {
            return "1 day ago";
        }
        return days + " days ago";
    }

    const months = Math.round(diff_hours / 24 / 30);
    if (months == 1) {
        return "1 month ago";
    }
    return months + " months ago";
}

//
// Encode article IDs for use in article links.
//
function encode_article_id(id) {    
    id = parseInt(id);
    var num = 13 * id + 710123;
    return num;
}

//
// Decode article IDs for use in article links.
//
function decode_article_id(num) {
    var id = Math.round((num - 710123) / 13);
    return id;
}

export { encode_article_id, decode_article_id, article_age, parse_markdown, markdown_elements_to_html, markdown_to_html };

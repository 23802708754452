//
// This component is used to display a single policy.
//

import React, { useEffect, useState } from "react";
import { config } from "./Config";
import { article_age, markdown_to_html } from "./Utils";

//
// Convert markdown text to HTML
//
// props.text: The markdown text
//
function PolicyBody(props) {
    const text = props.text

    return markdown_to_html(text);
}

//
// Show a single policy
//
// props.id: The policy tag
// props.dark: true | false
//
function Policy(props) {
    const tag = props.tag;
    const dark = props.dark;

    var color = "w3-white w3-text-black";
    if (dark) {
      color = "w3-black w3-text-white" // dark mode
    }

    const empty_data = {
        policy_id: "1",
        tag: "",
        title: "",
        body: "",
        added: "",
        modified: "",
        published: "",
    };

    const [data, setData] = useState(empty_data);

    // This could be done more efficiently
    const fetchPolicyData = () => {
      fetch(config.php_url + "/policy.php?tag=" + tag, {method: 'GET'})
        .then(response => {
          console.log(response)
          return response.json()
        })
        .then(res_data => {
          console.log(res_data);
          if ('error' in res_data) {
            console.error('Error:', res_data.error);
          }
          else {
            setData(res_data);
          }
        })
        .catch((error) => {
          console.error(error);
       })
    }
  
    useEffect(() => {
      fetchPolicyData();
    }, [tag,dark]);
  
    return (
        <div className={"w3-card-2 w3-margin w3-round " + color}>
            <div className="w3-container">
              <h1><b>{data.title}</b></h1>
            </div>
            <div className="w3-container">
              <PolicyBody text={data.body} />
            </div>
        </div>
    );
}


export default Policy;


import React, { useEffect  } from 'react';

function AdMarginSmall(props) {
    // const { dataAdSlot } = props;  

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});

            const c_cons = localStorage.getItem("cookie_consent");
            if (c_cons === "true") {
                window.adsbygoogle.requestNonPersonalizedAds = 0;
                console.log("requestNonPersonalizedAds = 0");
            }
            else {
                window.adsbygoogle.requestNonPersonalizedAds = 1;
                console.log("requestNonPersonalizedAds = 1");
            }
        }
        catch (e) {
            console.log(e);
        }
    },[]);

    return (
    <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7605875382421097"
        data-ad-slot="2059306333"
        data-ad-format="auto"
        data-full-width-responsive="true">
    </ins>
    );
};

export default AdMarginSmall;
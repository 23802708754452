//
// This is a component that shows a list of latest/mostread/trending/search/preview articles on the main page
// TODO: Should also place ad after the articles
//

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { config } from "./Config";
import { encode_article_id, decode_article_id } from "./Utils";

//
// props.view_type: "latest" | "mostread" | "trending" | "search" | "preview"
// props.id: article_id     // only for preview
// props.query: query       // only for search
// props.dark: true | false
// 
function ArticleListFront(props) {
    const view_type = props.view_type;
    const id = props.id;
    const dark = props.dark;
    const query = props.query;
    const count_step = 7; // TODO: Could be in config

    var color = "w3-white w3-text-black";
    if (dark) {
      color = "w3-black w3-text-white" // dark mode
    }

    const [count, setCount] = useState(count_step);

    const empty_data = Array(count).fill({
        article_id: "1",
        title: "-",
        keywords: null,
        abstract: "-",
        image_alt: "",
        image_url: "loading-1.jpg",
        added: "2023-07-24 23:55:18",
        modified: "2023-07-24 23:55:18",
        published: "",
        error: ""
    });

    const [data, setData] = useState(empty_data);

    var title = "";
    var url = "";
    if (view_type == "latest") {
        url = config.php_url + "/latest.php?count=" + count + "&compact=1";
        title = "Latest";
    }
    else if (view_type == "mostread") {
        url = config.php_url + "/popular.php?tag=mostread&count=" + count + "&compact=1";
        title = "Most read";
    }
    else if (view_type == "trending") {
        url = config.php_url + "/popular.php?tag=trending-week&count=" + count + "&compact=1";
        title = "Trending";
    }
    else if (view_type == "search") {
      url = config.php_url + "/search.php?count=" + count + "&query=" + encodeURIComponent(query) + "&compact=1";
      title = "Search Results";
    }
    else if (view_type == "preview") {
        var ids = id;
        for (var i = 1; i < count; i++) {
          ids = ids + "," + id;
        }
        url = config.php_url + "/titles.php?ids=" + ids + "&compact=1";
        title = "Preview of article " + id + " / article-" + encode_article_id(id);
    }
  
    // This could be done more efficiently
    const fetchLatestData = () => {
      fetch(url, {method: 'GET'})
        .then(response => {
          console.log(response)
          return response.json()
        })
        .then(res_data => {
          console.log(res_data);
          if ('error' in res_data) {
            console.error('Error:', res_data.error);
          }
          else {
            setData(res_data);
          }
        })
        .catch((error) => {
          console.error(error);
       })
    }
  
    useEffect(() => {
      fetchLatestData();
    }, [id, dark, count, view_type, query]);

    return ([
        <h1><b>{title}</b></h1>,
        data.map(article =>
        <div>
            <Link to={"/article-" + encode_article_id(article.article_id)} onClick={() => {window.scrollTo(0, 0);}}>
            <div className={"w3-card-2 w3-margin w3-round w3-hover-shadow zoom-small " + color}>
                <div className="w3-row">
                    <div className="w3-col l3 m3 s3">
                        <img src={config.pic_url + '/' + article.image_url} alt={article.image_alt} style={{width:'100%'}} />
                    </div>
                    <div className="w3-col l9 m9 s9">
                        <article className="w3-container">
                            <p>{article.title}</p>
                        </article>
                    </div>
                </div>
            </div>
            </Link>
        </div>),
        <div className={"w3-card-2 w3-margin w3-round w3-hover-shadow zoom-small " + color} style={{textAlign: "center", cursor: "pointer"}} onClick={() => setCount(count + count_step)}>
            &#9660; Show more ... &#9660;
        </div>]
    );
}

export default ArticleListFront;

//
// This component is used to display a single article.
//

import React, { useEffect, useState } from "react";
import { config } from "./Config";
import { article_age, markdown_to_html } from "./Utils";

//
// Convert markdown text to HTML
//
// props.text: The markdown text
//
function ArticleBody(props) {
    const text = props.text

    return markdown_to_html(text);
}

//
// Show a single article
//
// props.id: The article ID
// props.dark: true | false
//
function Article(props) {
    const id = props.id;
    const dark = props.dark;

    var color = "w3-white w3-text-black";
    if (dark) {
      color = "w3-black w3-text-white" // dark mode
    }

    const empty_data = {
        article_id: "1",
        article_type: "review",
        editor: null,
        author: null,
        title: "-",
        keywords: null,
        abstract: "-",
        image_alt: "",
        image_url: "loading-1.jpg",
        image_caption: " ",
        body: "-",
        added: "2023-07-24 23:55:18",
        modified: "2023-07-24 23:55:18",
        published: "",
        error: ""
    };

    const [data, setData] = useState(empty_data);

    // This could be done more efficiently
    const fetchArticleData = () => {
      fetch(config.php_url + "/fetch.php?id=" + id, {method: 'GET'})
        .then(response => {
          console.log(response)
          return response.json()
        })
        .then(res_data => {
          console.log(res_data);
          if ('error' in res_data) {
            console.error('Error:', res_data.error);
          }
          else {
            setData(res_data);
          }
        })
        .catch((error) => {
          console.error(error);
       })
    }
  
    useEffect(() => {
      fetchArticleData();
    }, [id,dark]);
  
    return (
        <div className={"w3-card-2 w3-margin w3-round " + color}>
            <div className="w3-container">
              <div className="w3-left">Author: {data.author == null ? "anon" : data.author}</div>
              <div className="w3-right">Published {article_age(data.published)}</div>
            </div>
            <div className="w3-container">
              <h1><b>{data.title}</b></h1>
              <p><i>{data.abstract}</i></p>
            </div>
            <img src={config.pic_url + '/' + data.image_url} alt={data.image_alt} style={{width: "100%"}} />
            <div className="w3-margin-left">
                <i>{data.image_caption}</i>
            </div>
            <div className="w3-container">
              <ArticleBody text={data.body} />
            </div>
        </div>
    );
}


export default Article;


//
// This is a component that shows a briefs of headline articles on the main page
// TODO: Should also place ad after the articles
//

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { config } from "./Config";
import { encode_article_id, article_age } from "./Utils";

function HeadlineView(props) {
    const id = props.id; // If given, only show this article
    const dark = props.dark;
    const count = 7;

    var color = "w3-white w3-text-black";
    if (dark) {
      color = "w3-black w3-text-white" // dark mode
    }

    const empty_data = Array(count).fill({
        article_id: "1",
        title: "-",
        keywords: null,
        abstract: "-",
        image_alt: "",
        image_url: "loading-1.jpg",
        added: "2023-07-24 23:55:18",
        modified: "2023-07-24 23:55:18",
        published: "",
        error: ""
    });

    const [data, setData] = useState(empty_data);

    var url = "";
    if (id) {
        var ids = id;
        for (var i = 1; i < count; i++) {
          ids = ids + "," + id;
        }
        url = config.php_url + "/titles.php?ids=" + ids + "&compact=1";
    }
    else {
        url = config.php_url + "/headline.php?count=" + count + "&compact=1";
    }

    // This could be done more efficiently
    const fetchLatestData = () => {
        fetch(url, {method: 'GET'})
          .then(response => {
            console.log(response)
            return response.json()
          })
          .then(res_data => {
            console.log(res_data);
            if ('error' in res_data) {
              console.error('Error:', res_data.error);
            }
            else {
              setData(res_data);
            }
          })
          .catch((error) => {
            console.error(error);
         })
    }
    
    useEffect(() => {
        fetchLatestData();
    }, [id, dark]);

    var len = data.length;
    if (len % 2 == 0) { // Make sure we have an odd number of articles
      len = len - 1;
    }

    const num_pairs = (len - 1) / 2;
    let paired_data = [];
    for (var i = 0; i < num_pairs; i++) {
      paired_data.push([data[2 * i + 1], data[2 * i + 2]]);
    }

    return [
      <div className="w3-row">
          <Link style={{textDecoration: "none"}} to={"/article-" + encode_article_id(data[0].article_id)} onClick={() => {window.scrollTo(0, 0);}}>
          <div className={"w3-card-2 w3-margin w3-round w3-hover-shadow zoom-small " + color}>
              <img src={config.pic_url + '/' + data[0].image_url} alt={data[0].image_alt} style={{width: "100%"}} />
              <div className="w3-container">
                <p className="w3-xlarge"><b>{data[0].title}</b></p>
                <p><i>{data[0].abstract}</i></p>
              </div>
              <div className="w3-container">
              <div className="w3-right">Published {article_age(data[0].published)}</div>
              </div>
          </div>
          </Link>
      </div>,
      paired_data.map(pair =>
        <div className="w3-row col-container">
          {pair.map(article => 
          <div className="w3-half">
            <Link style={{textDecoration: "none"}} to={"/article-" + encode_article_id(article.article_id)} onClick={() => {window.scrollTo(0, 0);}}>
            <div className={"w3-card-2 w3-margin w3-round w3-hover-shadow zoom-small " + color} >
                <img src={config.pic_url + '/' + article.image_url} alt={article.image_alt} style={{width: "100%"}} />
                <div className="w3-container">
                  <p className=""><b>{article.title}</b></p>
                </div>
                <div className="w3-container">
                <div className="w3-right w3-small">Published {article_age(article.published)}</div>
                </div>
            </div>
            </Link>
          </div>)}
        </div>)
    ]

    /*
    return (<div className="w3-row">{data.map(article =>
        <div className={article.article_id == data[0].article_id ? "" : "w3-half"}>
            <Link style={{textDecoration: "none"}} to={"/article-" + encode_article_id(article.article_id)} onClick={() => {window.scrollTo(0, 0);}}>
            <div className={"w3-card-2 w3-margin w3-round w3-hover-shadow zoom-small " + color}>
                <img src={config.pic_url + '/' + article.image_url} alt={article.image_alt} style={{width: "100%"}} />
                <div className="w3-container">
                  <p className={article.article_id == data[0].article_id ? "w3-xlarge" : "w3-large"}><b>{article.title}</b></p>
                  {article.article_id == data[0].article_id  ? <p><i>{article.abstract}</i></p> : ""}
                </div>
                <div className="w3-container">
                <div className="w3-right">Published {article_age(article.published)}</div>
                </div>
            </div>
            </Link>
        </div>)}</div>
    );
    */



}

export default HeadlineView;

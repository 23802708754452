const prod = {
    pic_url: "/pics",
    php_url: "/php",
    preview_url: "https://ai-and-science.com/article"    
};

const dev = {
    pic_url: "http://localhost/pics",
    php_url: "/php",
    preview_url: "http://localhost:3000/article"    
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;


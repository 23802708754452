//
// This is a component that shows a list of latest/mostread/trending/preview articles on the side
//

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { config } from "./Config";
import { encode_article_id, decode_article_id } from "./Utils";

//
// props.view_type: "latest" | "mostread" | "trending" | "preview"
// props.id: article_id     // only for preview
// props.dark: true | false
// 
function ArticleListSide(props) {
    const view_type = props.view_type;
    const id = props.id;
    const count = 5;
    const dark = props.dark;

    var color = "w3-white w3-text-black";
    var color2 = "w3-titleblue neonText";
    if (dark) {
      color = "w3-black w3-text-white" // dark mode
      color2 = "w3-titleblue neonText"; // dark mode
    }

    const empty_data = Array(count).fill({
        article_id: "1",
        title: "-",
        keywords: null,
        added: "2023-07-24 23:55:18",
        modified: "2023-07-24 23:55:18",
        published: "",
        error: ""
    });

    const [data, setData] = useState(empty_data);

    var title = "";
    var url = "";
    if (view_type == "latest") {
      url = config.php_url + "/latest.php?count=" + count + "&compact=2";
      title = "Latest";
    }
    else if (view_type == "mostread") {
      url = config.php_url + "/popular.php?tag=mostread&count=" + count + "&compact=2";
      title = "Most read";
    }
    else if (view_type == "trending") {
      url = config.php_url + "/popular.php?tag=trending-week&count=" + count + "&compact=2";
      title = "Trending";
    }
    else if (view_type == "preview") {
      var ids = id;
      for (var i = 1; i < count; i++) {
        ids = ids + "," + id;
      }
      url = config.php_url + "/titles.php?ids=" + ids + "&compact=2";
      title = "Article " + id;
    }

    // This could be done more efficiently
    const fetchLatestData = () => {
      fetch(url, {method: 'GET'})
        .then(response => {
          console.log(response)
          return response.json()
        })
        .then(res_data => {
          console.log(res_data);
          if ('error' in res_data) {
            console.error('Error:', res_data.error);
          }
          else {
            setData(res_data);
          }
        })
        .catch((error) => {
          console.error(error);
       })
    }
  
    useEffect(() => {
      fetchLatestData();
    }, [id, dark, view_type]);

    return (
        <div className="w3-card w3-margin w3-hover-shadow">
          <div className={"w3-container titleblue"}>
            <div className={color2}><h4><b>{title}</b></h4></div>
          </div>
          <div className="w3-bar-block">
            {data.map(article =>
            <Link className={"w3-bar-item w3-button w3-border " + color} to={"/article-" + encode_article_id(article.article_id)} onClick={() => {window.scrollTo(0, 0);}}>
            {article.title}
            </Link>)}
          </div>
        </div>
    );
}

export default ArticleListSide;

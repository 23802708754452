import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

// import './App.css';
import './w3.css';
import './my.css';
import Article from "./Article"
import Policy from "./Policy"
import Layout from "./Layout"
import ArticleListSide from "./ArticleListSide";
import ArticleListFront from "./ArticleListFront";
import HeadlineView from "./HeadlineView";
import AdMarginSmall from "./AdMarginSmall";
import { encode_article_id, decode_article_id } from "./Utils";

//
// This is supposed to be the home (headline) page
// TODO: implement headlines
//
function HomeView(props) {

  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <HeadlineView dark={dark}/>

      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="latest"   id="0" dark={dark}/>
        <ArticleListSide view_type="mostread" id="0" dark={dark}/>
        <ArticleListSide view_type="trending" id="0" dark={dark}/>
        <AdMarginSmall />
      </div>
    </div>
  );
}


//
// Show the articles latest/mostread/trending
//
// props.view_type: "latest" | "mostread" | "trending"
//
function ArticleListView(props) {
  const view_type = props.view_type;

  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  var title = "";
  var view1 = "";
  var view2 = "";

  if (view_type == "latest") {
    title = "Latest";
    view1 = "trending";
    view2 = "mostread";
  }
  else if (view_type == "mostread") {
    title = "Most Read";
    view1 = "latest";
    view2 = "trending";
  }
  else if (view_type == "trending") {
    title = "Trending";
    view1 = "latest";
    view2 = "mostread";
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <ArticleListFront view_type={view_type} dark={dark}/> 
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type={view1} dark={dark}/>
        <ArticleListSide view_type={view2} dark={dark}/>
        <AdMarginSmall />
      </div>
    </div>
  );
}


//
// This is supposed to be the search page
// TODO: implement
//
function SearchView(props) {

  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  //
  // Search query handling
  //
  const [query, setQuery] = useState("");

  // Callback for changing the search query
  function setQueryField(value) {
    console.log("setQueryField: " + value);
    setQuery(value);
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <h1><b>Search</b></h1>

        <div className="w3-container">
        <p>
          <label for="query">Query:</label> 
          <input type="search" name="query" id="query" style={{ width: "100%"}} placeholder="Type space separated search words here ..." onChange={e => setQueryField(e.target.value)} />
        </p>
        </div>

        <ArticleListFront view_type="search" query={query} dark={dark}/> 
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="latest"   dark={dark}/>
        <ArticleListSide view_type="mostread" dark={dark}/>
        <ArticleListSide view_type="trending" dark={dark}/>
        <hr />
      </div>
    </div>
  );
}

//
// This shows policy with tag and dark taken from props
//
function PolicyView(props) {
  const tag = props.tag;
  const dark = props.dark;

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <Policy tag={tag} dark={dark}/>
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="latest"   dark={dark}/>
        <ArticleListSide view_type="mostread" dark={dark}/>
        <ArticleListSide view_type="trending" dark={dark}/>
        <hr />
      </div>
    </div>
  );
}



//
// This shows an article with id and dark taken from props
//
function ArticleViewWithId(props) {
  const id = props.id;
  const dark = props.dark;

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <Article id={id} dark={dark}/>
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="latest"   dark={dark}/>
        <ArticleListSide view_type="mostread" dark={dark}/>
        <ArticleListSide view_type="trending" dark={dark}/>
        <AdMarginSmall />
      </div>
    </div>
  );
}

//
// This shows a preview of article with id taken from params and dark mode from context
//
function ArticlePreview(props) {

  //
  // Get article id as url parameter
  //
  let { id } = useParams();

  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <Article id={id} dark={dark}/>
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="preview" id={id} dark={dark}/>
        <hr />
      </div>

      <div className="w3-col l8 s12">
        <ArticleListFront view_type="preview" id={id} dark={dark}/> 
      </div>

    </div>
  );


}


//
// Show error page
//
function MissingView(props) {
  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  return (
    <div className={"w3-row " + color}>
      <div className="w3-col l8 s12">
        <h1><b>Nothing to see here!</b></h1>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>

      <div className="w3-col l4">
        <ArticleListSide view_type="latest"   dark={dark}/>
        <ArticleListSide view_type="mostread" dark={dark}/>
        <ArticleListSide view_type="trending" dark={dark}/>
        <AdMarginSmall />
      </div>
    </div>);
}


//
// Either show an article view if parsing of the path is successful,
// otherwise show an error page.
//
function ParsedView(props) {
  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = useOutletContext();

  var color = "w3-light-gray w3-text-black";
  if (dark) {
    color = "w3-text-light-gray w3-dark-gray" // dark mode
  }

  // Try to get article id as path part
  let { id } = useParams();

  console.log('Id: ' + id);

  // Check that id starts with substring "article-"
  if (id.startsWith("article-")) {
    // Parse the numeric article id from the end of the id string
    var article_id = parseInt(id.substring(8));
    article_id = decode_article_id(article_id);
    if (article_id > 0) {
      return (<ArticleViewWithId id={article_id} dark={dark}/>);
    }
    else {
      return (<MissingView/>);
    }
  }
  else {
    return (<MissingView/>);
  }
}


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeView/>} />
          <Route path="latest" element={<ArticleListView view_type="latest" id="0"/>} />
          <Route path="mostread" element={<ArticleListView view_type="mostread" id="0"/>} />
          <Route path="trending" element={<ArticleListView view_type="trending" id="0"/>} />
          <Route path="search" element={<SearchView/>} />
          <Route path="editorial-policy" element={<PolicyView tag="editorial-policy"/>} />
          <Route path="privacy-policy" element={<PolicyView tag="privacy-policy"/>} />
          <Route path="cookie-policy" element={<PolicyView tag="cookie-policy"/>} />
          <Route path="about" element={<PolicyView tag="about"/>} />
          <Route path="preview">
            <Route path=":id" element={<ArticlePreview/>} />
            <Route index element={<MissingView/>} />
          </Route>
          <Route path=":id" element={<ParsedView/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
